import { AdvicePresentation, IAdvice, ScoreDeterminantType } from '@studyportals/bestfit-api-fe-client';
import {
	FitLevel,
	GetFitByDeterminantCommand,
	IBestfitOverviewApplication,
} from '@studyportals/bestfit-overview-interfaces';

export default class BestfitDataRepository {
	constructor(public bestfitApplication: IBestfitOverviewApplication) {}

	public determinantList = [
		ScoreDeterminantType.BUDGET_FIT,
		ScoreDeterminantType.DEGREE,
		ScoreDeterminantType.GPA,
		ScoreDeterminantType.ENGLISH_SKILL,
		ScoreDeterminantType.WORK_EXPERIENCE,
		ScoreDeterminantType.LIVING_COST,
		ScoreDeterminantType.TUITION_FEE,
		ScoreDeterminantType.ACADEMIC_FIT,
	];

	public async getFitLevels(studyId: number): Promise<IFitLevelPresentation> {
		const fitLevels = {};
		for (const determinantType of this.determinantList) {
			const fitLevel = await this.getFitLevel(determinantType, studyId);
			fitLevels[determinantType] = fitLevel;
		}
		return fitLevels;
	}

	public async getAdvices(studyId: number): Promise<IDeterminantPresentation> {
		const advices = {};

		for (const determinantType of this.determinantList) {
			const advice = await this.getAdvicePresentation(determinantType, studyId);
			advices[determinantType] = advice;
		}
		return advices;
	}

	private async getAdvicePresentation(
		determinantType: ScoreDeterminantType,
		studyId: number
	): Promise<IDeterminantPresentationReqQuali> {
		const commandTuition = new GetFitByDeterminantCommand(studyId, determinantType);
		const tuitionAdvice: IAdvice<AdvicePresentation, AdvicePresentation> | null =
			await this.bestfitApplication.getAdviceByDeterminant(commandTuition);

		return {
			req: tuitionAdvice?.requirement ?? null,
			quali: tuitionAdvice?.qualification ?? null,
		};
	}

	private async getFitLevel(determinantType: ScoreDeterminantType, studyId: number): Promise<FitLevel | null> {
		const command = new GetFitByDeterminantCommand(studyId, determinantType);
		const fit = await this.bestfitApplication.getFitByDeterminant(command);
		return fit;
	}
}

export interface IDeterminantPresentationReqQuali {
	req: AdvicePresentation | null;
	quali: AdvicePresentation | null;
}

export interface IDeterminantPresentation {
	[determinantType: number]: {
		req: AdvicePresentation | null;
		quali: AdvicePresentation | null;
	};
}

export interface IFitLevelPresentation {
	[determinantType: number]: FitLevel | null;
}

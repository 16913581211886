/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { ISessionService, IWishlistService } from '@studyportals/student-interfaces';
import { IBestfitOverviewApplication } from '@studyportals/bestfit-overview-interfaces';
import mitt from 'mitt';
import actions from '../lib/tracking/actions';
import categories from '../lib/tracking/categories';
import Tracking from './tracking';
import { PortalType } from '@studyportals/domain-client/src/types/portal-type';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import BestfitDataRepository from '@/infrastructure/bestfit-data-repository';
import { ILocalizedContentService } from '@studyportals/portal-localized-content';
import { StudentRepository } from '@/domain/student-repository';
import DecisionMakingServiceApplication from '@/application/decision-making-service-application';
import JourneyTracker from '@/presentation/components/Views/Journey/JourneyTracker';

// set in dependency checker plugin
const sessionService: ISessionService = {} as ISessionService;
const eventAggregationService: IEventAggregationService = {} as IEventAggregationService;
const anonymousStudent: IAnonymousStudentService = {} as IAnonymousStudentService;
const localizedContentService: ILocalizedContentService = {} as ILocalizedContentService;

const tracking = new Tracking();
const wishlistService: IWishlistService = {} as IWishlistService;
const eventBus = mitt();
const portalType: PortalType = window['PortalType'];
const bestfitDataRepository: BestfitDataRepository | null = null as BestfitDataRepository | null;
const bestfitApplication: IBestfitOverviewApplication | null = null as IBestfitOverviewApplication | null;
const studentRepository: StudentRepository | null = null as StudentRepository | null;
const decisionMakingServiceApplication: DecisionMakingServiceApplication | null =
	null as DecisionMakingServiceApplication | null;
const journeyTracker: JourneyTracker = new JourneyTracker();

const trackingLibrary = {
	actions,
	categories,
};

export default {
	sessionService,
	eventAggregationService,
	anonymousStudent,
	trackingLibrary,
	tracking,
	wishlistService,
	eventBus,
	portalType,
	bestfitApplication,
	bestfitDataRepository,
	localizedContentService,
	studentRepository,
	decisionMakingServiceApplication,
	journeyTracker,
};

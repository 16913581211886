import store from '@/store';
import { Favourite, FavouriteAdded } from '@studyportals/wishlist-service-core';
import globals from '../utils/globals';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { WishlistEntry } from '@/interfaces/wishlist-entry.interface';

export class FavouriteAddedService implements ISubscriber<FavouriteAdded> {
	public initialize(): void {
		globals.eventAggregationService.subscribeTo(FavouriteAdded.EventType, this, true);
	}

	public async notify(event: FavouriteAdded): Promise<void> {
		await this.add(event);
	}

	public async add(event: FavouriteAdded): Promise<void> {
		if (store.getters.isInitialized()) {
			await this.addFavourite(event?.studyId);
		}
	}

	public async addFavourite(studyId): Promise<void> {
		const addedFavourite = await this.getAddedFavourite(studyId);
		if (!addedFavourite) {
			return;
		}

		if (this.favouriteExists(addedFavourite)) {
			store.mutations.undoDeletion(addedFavourite?.study.id);
			return;
		}

		const entry: WishlistEntry = {
			study: addedFavourite.study,
			dateAdded: addedFavourite.dateAdded,
			applicationStatus: addedFavourite.properties.applicationStatus,
			assessments: addedFavourite.properties.assessments,
			deleted: false,
		};

		store.mutations.add(entry);

		document.dispatchEvent(new Event('premium_click_tracker_update_listeners'));
	}

	private favouriteExists(addedFavourite: Favourite): boolean {
		let favouriteExists = false;
		const studyId = addedFavourite.study?.id;
		favouriteExists = store.getters.entries().some((entry) => entry.study.id === studyId);

		return favouriteExists;
	}

	private async getAddedFavourite(studyId): Promise<Favourite | undefined> {
		const wishlist = await globals.wishlistService.getWishlist();
		const addedFavourite = wishlist.favourites.find((favourite) => favourite.study?.id === studyId);
		return addedFavourite;
	}
}

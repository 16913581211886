import { computed, watch } from 'vue';
import { LoginStatusCheckerPlugin } from '@/plugins/login-status-checker-plugin';
import { WishlistInitializationPlugin } from '@/plugins/wishlist-initialization-plugin';
import store from '.';
import { RemoveCommand } from '../domain/remove-command';
import { IActions } from '../interfaces/store.interfaces';
import globals from '@/utils/globals';
import { FavouriteApplication } from '@studyportals/wishlist-service-core';
import { JourneyStepChangedEvent, StepType } from '@studyportals/account-page-interfaces';
import { router } from '@/router/router';
import { JourneyRouteName } from '@/domain/enums/journey-route-name';

const actions: IActions = {
	remove: async (studyId: number): Promise<RemoveCommand> => {
		const removeCommand = new RemoveCommand(studyId);
		await removeCommand.action();
		store.mutations.reversableRemove(studyId);
		return removeCommand;
	},
	setAreDependenciesLoaded: async (areDependenciesLoaded: boolean): Promise<void> => {
		store.mutations.setAreDependenciesLoaded(areDependenciesLoaded);

		if (areDependenciesLoaded) {
			const loginStatusCheckerPlugin = new LoginStatusCheckerPlugin();
			await loginStatusCheckerPlugin.initialize();
			store.mutations.setIsReady(true);

			store.actions.addRouterListener();
		}
	},
	setLoggedInFlag: async (isLoggedIn: boolean): Promise<void> => {
		if (isLoggedIn) {
			const wishlistInitializationPlugin = new WishlistInitializationPlugin();
			await wishlistInitializationPlugin.initialize();
		}

		store.mutations.setLoggedInFlag(isLoggedIn);
	},
	setIsApplyingStatus: async (studyId: number, isApplying: boolean): Promise<void> => {
		const entry = store.getters.entries().find((e) => e.study.id === studyId);

		const oldApplicationStatus = entry?.applicationStatus ?? {};

		const applicationStatus = {
			...oldApplicationStatus,
			isApplying,
		};
		store.mutations.setStudyApplicationStatus(studyId, applicationStatus);

		await globals.wishlistService.setFavouriteApplicationStatus(studyId, applicationStatus);
	},
	setHasAppliedStatus: async (studyId: number, hasApplied: boolean): Promise<void> => {
		const entry = store.getters.entries().find((e) => e.study.id === studyId);

		const oldApplicationStatus = entry?.applicationStatus ?? {};

		const applicationStatus = {
			...oldApplicationStatus,
			hasApplied,
		};
		store.mutations.setStudyApplicationStatus(studyId, applicationStatus);

		await globals.wishlistService.setFavouriteApplicationStatus(studyId, applicationStatus);
	},

	setFavouriteApplication: async (
		studyId: number,
		favouriteApplication: FavouriteApplication | null
	): Promise<void> => {
		const entry = store.getters.entries().find((e) => e.study.id === studyId);

		delete entry?.application;

		store.mutations.setStudyApplication(studyId, favouriteApplication);

		await globals.wishlistService.setFavouriteApplication(studyId, favouriteApplication);
	},

	addRouterListener: (): void => {
		globals.eventAggregationService.subscribeTo(JourneyStepChangedEvent.EventType, {
			notify: async (event: JourneyStepChangedEvent): Promise<void> => {
				if (![StepType.EVALUATE, StepType.PREPARE_TO_APPLY].includes(event.stepType)) {
					return;
				}

				const route = router.resolve(router.currentRoute.value);

				if (!route.path.includes(`/${event.stepType}/`)) {
					const routeName =
						event.stepType === StepType.EVALUATE ? JourneyRouteName.EVALUATE_OVERVIEW : JourneyRouteName.APPLY_OVERVIEW;
					await router.replace({ name: routeName });
				}
			},
		});
	},

	forceAllConditionsForChecklist: async (studyId: number): Promise<void> => {
		return new Promise(async (resolve) => {
			let entry = computed(() => store.getters.getEntry(studyId));

			const unwatch = watch(
				entry,
				async () => {
					if (!entry.value) {
						return;
					}

					if (!entry.value.applicationStatus?.isApplying) {
						await actions.setIsApplyingStatus(studyId, true);
					}

					if (entry.value.applicationStatus?.isApplying) {
						unwatch();
						resolve();
					}
				},
				{ immediate: true }
			);

			if (entry.value === undefined) {
				await globals.wishlistService.addFavourite(studyId);
			}
		});
	},
};

export default actions;

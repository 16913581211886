import { JourneyRouteName } from '@/domain/enums/journey-route-name';
import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { ListType } from 'interfaces';

export default {
	path: 'apply/',
	component: (): any => import('@/presentation/components/Views/Journey/JourneyApply/JourneyApply.vue'),
	meta: {
		listType: ListType.JOURNEY_APPLY,
	},
	children: [
		{
			name: JourneyRouteName.APPLY_OVERVIEW,
			path: '',
			component: (): any =>
				import('@/presentation/components/Views/Journey/JourneyApply/JourneyApplyOverview/JourneyApplyOverview.vue'),
		},
		{
			name: JourneyRouteName.APPLY_UPDATE,
			path: ':id/update/',
			component: (): any =>
				import(
					'@/presentation/components/Views/Journey/JourneyApply/ApplicationChecklistBuilder/ApplicationChecklistBuilder.vue'
				),
			props: (props): Record<string, any> => ({ id: Number(props.params.id) }),
			beforeEnter: async (to, from, next): Promise<void> => {
				const entry = store.getters.getEntry(Number(to.params.id));
				const isApplying = !!entry?.applicationStatus?.isApplying;

				if (isApplying) {
					return next();
				}

				if (to.query.forceAllConditions === 'true') {
					await store.actions.forceAllConditionsForChecklist(Number(to.params.id));
					return next();
				}

				return next({ name: JourneyRouteName.APPLY_OVERVIEW });
			},
		},
		{
			name: JourneyRouteName.APPLY_VIEW,
			path: ':id/view/',
			component: (): any =>
				import(
					'@/presentation/components/Views/Journey/JourneyApply/ApplicationChecklistViewer/ApplicationChecklistViewer.vue'
				),
			props: (props): Record<string, any> => ({ id: Number(props.params.id) }),
			beforeEnter: (to, from, next): void => {
				const entry = store.getters.getEntry(Number(to.params.id));
				const hasApplication = !!entry?.application;

				return hasApplication ? next() : next({ name: JourneyRouteName.APPLY_OVERVIEW });
			},
		},
	],
} as RouteRecordRaw;

import { createRouter, createWebHistory, Router } from 'vue-router';

import JourneyApplyRoutes from '@/presentation/components/Views/Journey/JourneyApply/JourneyApplyRoutes';
import JourneyEvaluateRoutes from '@/presentation/components/Views/Journey/JourneyEvaluate/JourneyEvaluateRoutes';
import globals from '@/utils/globals';
import { ListType } from 'interfaces';
import store from '@/store';
import { JourneyRouteName } from '@/domain/enums/journey-route-name';

export const router: Router = createRouter({
	// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	routes: [
		{
			path: '/account/my-journey',
			children: [JourneyApplyRoutes, JourneyEvaluateRoutes],
		},
	],
	scrollBehavior: (to, from): any => {
		if (!to.path.includes('/account/my-journey') || to.query?.scrollTo || from.query?.scrollTo) return;

		return {
			top: 0,
			behavior: 'smooth',
		};
	},
});

router.beforeEach((to, from, next) => {
	if (!to.name || !from.name) {
		store.mutations.setIsLoadingRoute(true);
		return next();
	}

	if (to.meta.listType !== from.meta.listType) {
		globals.decisionMakingServiceApplication?.unmountList(from.meta.listType as ListType);
	}

	next();
});

router.afterEach(() => {
	store.mutations.setIsLoadingRoute(false);
});

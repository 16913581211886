import { IStudent, StudentField } from '@studyportals/studentdomain';
import { StudentClient } from '../infrastructure/student-client';

export class StudentRepository {
	private studentClient: StudentClient = new StudentClient();

	public async getFields(fields: StudentField[]): Promise<IStudent | null> {
		return await this.studentClient.getData(fields);
	}
}

import store from '@/store';
import { FavouriteApplicationStatusChanged } from '@studyportals/wishlist-service-core';
import globals from '../utils/globals';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class FavouriteApplicationStatusChangedService implements ISubscriber<FavouriteApplicationStatusChanged> {
	public initialize(): void {
		globals.eventAggregationService.subscribeTo(FavouriteApplicationStatusChanged.EventType, this);
	}

	public notify(event: FavouriteApplicationStatusChanged): void {
		store.mutations.setStudyApplicationStatus(event.studyId, event.applicationStatus);
	}
}

import { defineComponent, defineAsyncComponent } from 'vue';
import App from './App.class';
import { setupComponent } from '@studyportals/vue-platform';
import Loading from '@/presentation/components/Views/Journey/Loading/Loading.vue';

export default defineComponent({
	components: {
		Loading,
		Wishlist: defineAsyncComponent(() => import('./presentation/components/Views/Wishlist/Wishlist.vue')),
		PreLoader: defineAsyncComponent(() => import('./presentation/components/Views/Wishlist/PreLoader/PreLoader.vue')),
		Notify: defineAsyncComponent(() => import('./presentation/components/Notify/Notify.vue')),
		ComparisonMode: defineAsyncComponent(
			() => import('./presentation/components/Views/Wishlist/ComparisonMode/ComparisonMode.vue')
		),
		JourneyEvaluate: defineAsyncComponent(
			() => import('./presentation/components/Views/Journey/JourneyEvaluate/JourneyEvaluate.vue')
		),
		JourneyApply: defineAsyncComponent(
			() => import('./presentation/components/Views/Journey/JourneyApply/JourneyApply.vue')
		),
	},
	props: {
		listType: {
			type: String,
			required: true,
		},
	},
	setup: () => {
		const component = new App();
		return setupComponent(component);
	},
});

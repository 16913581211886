export default {
	OpenedDrawer: 'opened_drawer',
	ClosedDrawer: 'closed_drawer',
	FavouriteRemove: 'favourite_remove',
	ProgramTitleClick: 'program_title - click',
	StudyCardFooterClick: 'studycard_footer - click',
	TimeSpentInWishList: 'time_spent_in_wishlist',
	ApplyNowClick: 'apply_now - click',
	ClickedOnUniversityLinkOnNotAvailableTooltip: 'clicked_on_university_link_not_available_tooltip',
	WishlistLimitReachedShowed: 'wishlist_limit_reached_showed',
	ApplicationPartnerShowed: 'application_partner_showed',
	UnavailableStudyIconClick: 'unavailable_study_icon - click',
	UnavailableStudyUniversityLinkClick: 'unavailable_study_university_link - click',
	UnavailableStudiesWarningShowed: 'unavailable_studies_warning_showed',
	TimeSpent: 'wishist_overview - timespent',
	UndoRemove: 'undo_remove',
	ToggleCompareMode: 'toggle_compare_mode',
	StartComparison: 'start_comparison',
	SelectedStudiesInfoClick: 'selected_studies_info - click',
	ToggleComparisonStudy: 'toggle_comparison_study',
	NavigationClickTutorial: 'navigation_click_tutorial',
	PremiumLinkClick: 'premium_link - click',
};

import { App as VueApp, createApp, h } from 'vue';
import { ListType, RenderCardsCommand } from '../../interfaces';
import { IDecisionMakingServiceApplication } from './../../interfaces/decision-making-service.interface';
import App from '@/App.vue';
import { router } from '@/router/router';

export default class DecisionMakingServiceApplication implements IDecisionMakingServiceApplication {
	private apps: {
		app: VueApp;
		listType: ListType;
	}[] = [];

	public renderCards(command: RenderCardsCommand): void {
		this.mountApplication(command);
	}

	private mountApplication(command: RenderCardsCommand): void {
		if (!command?.detail?.placeholderElement) {
			console.warn('No placeholder element found. Cannot render cards.');
			return;
		}

		const props = {
			listType: command.detail.listType,
		};

		const app = createApp({
			render: () => {
				return h(App, props);
			},
		});

		app.use(router);
		app.mount(command.detail.placeholderElement);

		this.apps.push({ app, listType: command.detail.listType });
	}

	public unmountList(listType: ListType): void {
		const newList: {
			app: VueApp;
			listType: ListType;
		}[] = [];

		for (const appRecord of this.apps) {
			if (appRecord.listType === listType) {
				appRecord.app.unmount();
			} else {
				newList.push(appRecord);
			}
		}

		this.apps = newList;
	}
}

import store from '@/store';
import globals from '@/utils/globals';
import { RemoveCommandState } from './enums/remove-command-state';

export class RemoveCommand {
	public removeCommandState: RemoveCommandState;

	constructor(public studyId: number) {
		this.studyId = studyId;
		this.removeCommandState = RemoveCommandState.CREATED;
	}

	public async action(): Promise<void> {
		this.removeCommandState = RemoveCommandState.ATTEMPTED;

		try {
			await globals.wishlistService.removeFavourite(this.studyId);
			this.removeCommandState = RemoveCommandState.DONE;
		} catch (error) {
			this.removeCommandState = RemoveCommandState.FAILED;
		}
	}

	public async revert(): Promise<void> {
		this.removeCommandState = RemoveCommandState.ATTEMPTED;

		try {
			store.mutations.undoDeletion(this.studyId);
			await globals.wishlistService.addFavourite(this.studyId);
			this.removeCommandState = RemoveCommandState.DONE;
		} catch (error) {
			this.removeCommandState = RemoveCommandState.FAILED;
		}
	}
}

import { ModalManager } from '@studyportals/modal';
import { DecisionMakingServiceReadyEvent } from './../interfaces/events/decision-making-service-ready-event';
import DecisionMakingServiceApplication from './application/decision-making-service-application';
import { DependencyCheckerPlugin } from './plugins/dependency-checker-plugin';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { ProfileDataCheckerPlugin } from './plugins/profile-data-checker-plugin';
import globals from '@/utils/globals';

declare global {
	interface Window {
		EventAggregationService: IEventAggregationService;
		PortalMap: Record<string, string>;
		ModalManager: ModalManager;
	}
}

class Main {
	private readonly eventAggregationService: IEventAggregationService = window.EventAggregationService;

	public initialize(): void {
		if (!this.eventAggregationService) {
			console.warn('EventAggregationService not found. Cannot initialize decision making service.');
		}

		this.initializePlugins();
		this.publishDecisionMakingServiceApplication();
	}

	private initializePlugins(): void {
		void new DependencyCheckerPlugin().initialize();
		void new ProfileDataCheckerPlugin().initiate();
	}

	private publishDecisionMakingServiceApplication(): void {
		const decisionMakingServiceApplication = new DecisionMakingServiceApplication();

		this.eventAggregationService.publishTo(
			DecisionMakingServiceReadyEvent.EventType,
			new DecisionMakingServiceReadyEvent(decisionMakingServiceApplication)
		);

		globals.decisionMakingServiceApplication = decisionMakingServiceApplication;
	}
}

const main = new Main();
main.initialize();

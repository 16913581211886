import { computed, ref, Ref } from 'vue';
import store from '@/store';
import { v4 } from 'uuid';
import TimeSpentTimer from '@studyportals/timespenttimer';
import globals from './utils/globals';
import { ITimeSpentConfig } from '@studyportals/timespenttimer/bin/src/interfaces/time-spent-config-interface';
import { ListType } from 'interfaces';

export default class App {
	public listTypes = ListType;
	public limitNotificationType = 'Warning';
	public limitNotificationKey = 'WishlistLimitReached';
	public favouriteLimit = 99;
	public wishlistOpen: Ref<boolean> = ref(false);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	public timeSpentTimerIdentifier: string = v4();

	public timeSpentConfig: ITimeSpentConfig = {
		interval: 5000,
		idleThreshold: 10000,
		onTick: () => {
			if (!this.wishlistOpen.value) {
				return;
			}
			globals.tracking.trackTimeSpent(this.timeSpentTimerIdentifier, this.timeSpentConfig.interval ?? 5000);
		},
	};

	public timeSpentTimer = {} as TimeSpentTimer;
	public hasLoaded: Ref<boolean> = ref(false);

	public initialized = computed(() => {
		return store.getters.isInitialized();
	});

	public isReady = computed(() => {
		return store.getters.isReady();
	});

	public isLoadingRoute = computed(() => {
		return store.getters.isLoadingRoute();
	});

	public mounted(): void {
		this.attachListeners();
		globals.tracking.trackLimitReached(this.limitNotificationKey);
	}

	public openDrawer(): void {
		document.dispatchEvent(new Event('onWishlistOpen'));
	}

	private attachListeners(): void {
		this.addOpenWishlistDrawerListener();
		this.addCloseWishlistDrawerListener();
		this.addActiveComparisonModeListener();
	}

	private addOpenWishlistDrawerListener(): void {
		document.addEventListener('onWishlistOpen', () => {
			this.hasLoaded.value = true;
			this.wishlistOpen.value = true;
			globals.tracking.trackOpenWishlist();
			this.timeSpentTimer = new TimeSpentTimer(this.timeSpentConfig);
			this.timeSpentTimer.init();
		});
	}

	private addCloseWishlistDrawerListener(): void {
		document.addEventListener('closeWishlistDrawer', () => {
			this.wishlistOpen.value = false;
			globals.tracking.trackCloseWishlist();
		});
	}

	private addActiveComparisonModeListener(): void {
		document.addEventListener('activate_comparison_mode', () => {
			store.mutations.enableCompareMode();
		});
	}
}

import { IState } from '../interfaces/store.interfaces';
import { reactive } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const state: IState = reactive({
	initialized: false,
	entries: [],
	compareMode: false,
	comparisonStudies: [],
	isLoggedIn: false,
	areDependenciesLoaded: false,
	isReady: false,
	student_nationality_iso: '',
	timings: [],
	isLoadingRoute: false,
});

export default state;

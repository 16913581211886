import store from '@/store';
import { AnonymousStudentServiceReady } from '@studyportals/anonymous-student-interfaces';
import { SessionServiceReadyEvent, WishlistServiceReadyEvent } from '@studyportals/student-interfaces';

import globals from '../utils/globals';
import { BestfitOverviewReadyEvent } from '@studyportals/bestfit-overview-interfaces';
import { ISubscriber, IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import BestfitDataRepository from '@/infrastructure/bestfit-data-repository';
import { LocalizedContentServiceReadyEvent } from '@studyportals/portal-localized-content';

export class DependencyCheckerPlugin implements
	ISubscriber<
	| SessionServiceReadyEvent
	| AnonymousStudentServiceReady
	| WishlistServiceReadyEvent
	| BestfitOverviewReadyEvent
	| LocalizedContentServiceReadyEvent
	>
{
	private anonymousStudentIsReady = false;
	private sessionServiceIsReady = false;
	private wishlistServiceIsReady = false;
	private localisedContentIsReady = false;
	private hasSetDependenciesLoaded = false;

	public initialize(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const eventAggregationService: IEventAggregationService = window['EventAggregationService'];
		if (!eventAggregationService) {
			return;
		}
		globals.eventAggregationService = eventAggregationService;

		eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		eventAggregationService.subscribeTo(AnonymousStudentServiceReady.EventType, this, true);
		eventAggregationService.subscribeTo(WishlistServiceReadyEvent.EventType, this, true);
		eventAggregationService.subscribeTo(BestfitOverviewReadyEvent.EventType, this, true);
		eventAggregationService.subscribeTo(LocalizedContentServiceReadyEvent.EventType, this, true);
	}

	public async notify(
		event:
		| SessionServiceReadyEvent
		| AnonymousStudentServiceReady
		| WishlistServiceReadyEvent
		| BestfitOverviewReadyEvent
		| LocalizedContentServiceReadyEvent
	): Promise<void> {
		if (event.eventType === SessionServiceReadyEvent.EventType) {
			this.handleSessionServiceReady(event as SessionServiceReadyEvent);
		}

		if (event.eventType === AnonymousStudentServiceReady.EventType) {
			this.handleAnonymousStudentReady(event as AnonymousStudentServiceReady);
		}

		if (event.eventType === WishlistServiceReadyEvent.EventType) {
			this.handleWishlistServiceReady(event as WishlistServiceReadyEvent);
		}

		if (event.eventType === BestfitOverviewReadyEvent.EventType) {
			this.handleBestfitOverviewReady(event as BestfitOverviewReadyEvent);
		}

		if (event.eventType === LocalizedContentServiceReadyEvent.EventType) {
			await this.handleLocalizedContentReady(event as LocalizedContentServiceReadyEvent);
		}

		if (
			this.sessionServiceIsReady &&
			this.anonymousStudentIsReady &&
			this.wishlistServiceIsReady &&
			this.localisedContentIsReady &&
			!this.hasSetDependenciesLoaded
		) {
			this.hasSetDependenciesLoaded = true;
			await store.actions.setAreDependenciesLoaded(true);
		}
	}

	private handleWishlistServiceReady(event: WishlistServiceReadyEvent): void {
		globals.wishlistService = event.wishlistService;
		this.wishlistServiceIsReady = true;
	}

	private handleSessionServiceReady(event: SessionServiceReadyEvent): void {
		globals.sessionService = event.sessionService;
		this.sessionServiceIsReady = true;
	}

	private handleAnonymousStudentReady(event: AnonymousStudentServiceReady): void {
		globals.anonymousStudent = event.anonymousStudentService;
		this.anonymousStudentIsReady = true;
	}

	private handleBestfitOverviewReady(event: BestfitOverviewReadyEvent): void {
		globals.bestfitApplication = event.bestfitOverviewApplication;
		globals.bestfitDataRepository = new BestfitDataRepository(globals.bestfitApplication);
	}

	private async handleLocalizedContentReady(event: LocalizedContentServiceReadyEvent): Promise<void> {
		globals.localizedContentService = event.localizedContentService;
		await event.localizedContentService.update();

		this.localisedContentIsReady = true;
	}
}

import store from '@/store';
import globals from '@/utils/globals';
import { FavouriteRemoved } from '@studyportals/wishlist-service-core';
import { WishlistEntry } from '@/interfaces/wishlist-entry.interface';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class FavouriteRemovedService implements ISubscriber<FavouriteRemoved> {
	public initialize(): void {
		globals.eventAggregationService.subscribeTo(FavouriteRemoved.EventType, this, true);
	}

	public notify(event: FavouriteRemoved): void {
		this.remove(event);
	}

	public remove(event: FavouriteRemoved): void {
		const studyId = event?.studyId;

		if (!store.getters.isInitialized()) {
			return;
		}

		if (this.studyCanBeUndone(studyId)) {
			return;
		}
		store.mutations.remove(studyId);
	}

	private studyCanBeUndone(studyId): boolean {
		const entry = this.getFavouriteByStudyId(studyId);
		return entry?.deleted ? entry.deleted : false;
	}

	private getFavouriteByStudyId(studyId): WishlistEntry | undefined {
		const favouriteEntry = store.getters.entries().find((entry) => entry.study.id === studyId);
		return favouriteEntry;
	}
}

<template>
	<div v-if="listType === listTypes.WISHLIST">
		<Notify :type="limitNotificationType" :eventKey="limitNotificationKey">
			You reached the maximum amount of {{ favouriteLimit }} programmes in your wishlist.
			<a @click="openDrawer">Time to narrow it down!</a>
		</Notify>
		<div id="decisionMakingService" v-if="hasLoaded && isReady">
			<ComparisonMode v-show="wishlistOpen" />
			<Wishlist v-show="wishlistOpen" />
			<PreLoader v-show="wishlistOpen && !initialized" />
		</div>
	</div>

	<div v-else>
		<Loading v-show="isLoadingRoute"></Loading>
		<router-view />
	</div>
</template>

<script lang="ts" src="./App.ts"></script>

import store from '@/store';
import { FavouriteApplicationChanged } from '@studyportals/wishlist-service-core';
import globals from '../utils/globals';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class FavouriteApplicationChangedService implements ISubscriber<FavouriteApplicationChanged> {
	public initialize(): void {
		globals.eventAggregationService.subscribeTo(FavouriteApplicationChanged.EventType, this);
	}

	public notify(event: FavouriteApplicationChanged): void {
		store.mutations.setStudyApplication(event.studyId, event.application);
	}
}

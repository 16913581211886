import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { EventType, ListType, ViewType } from '../enums';

export class DecisionMakingServiceViewChangedEvent implements IEvent {
	public static EventType = EventType.DECISION_MAKING_SERVICE_VIEW_CHANGED;

	public readonly timestamp: Date;
	public readonly eventType = DecisionMakingServiceViewChangedEvent.EventType;

	constructor(public listType: ListType, public viewType: ViewType) {
		this.timestamp = new Date();
	}
}

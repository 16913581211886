import { IDecisionMakingServiceApplication } from './../decision-making-service.interface';
import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { EventType } from '../enums';

export class DecisionMakingServiceReadyEvent implements IEvent {
	public static EventType = EventType.DECISION_MAKING_SERVICE_READY;

	public readonly timestamp: Date;
	public readonly eventType = DecisionMakingServiceReadyEvent.EventType;

	constructor(public decisionMakingServiceApplication: IDecisionMakingServiceApplication) {
		this.timestamp = new Date();
	}
}

<template>
	<div class="LoadingImageContainer">
		<div class="LoadingImage">
			<img src="https://monet-prtl-co.imgix.net/Endor/ERT/loading-indicator-globe.svg" alt="Loading..." />
			Loading...
		</div>
	</div>
</template>

<style src="./Loading.scss" scoped></style>

import { IGetters } from '../interfaces/store.interfaces';
import state from './state';
import { WishlistEntry } from '@/interfaces/wishlist-entry.interface';

const getters: IGetters = {
	reversedEntries: () => {
		if (typeof state.entries === 'undefined') {
			return [];
		}

		return state.entries.slice().reverse();
	},
	isReady: () => {
		return state.isReady;
	},
	isInitialized: () => {
		return state.initialized;
	},
	isLoadingRoute: () => {
		return state.isLoadingRoute;
	},
	compareMode: () => {
		return state.compareMode;
	},
	isLoggedIn: () => {
		return state.isLoggedIn;
	},
	comparisonStudies: () => {
		return state.comparisonStudies;
	},
	entries: () => {
		return state.entries;
	},
	getEntry: (id: number): WishlistEntry | undefined => {
		return state.entries.find((entry) => entry.study?.id === id);
	},
	getStudentNationalityISO: () => state.student_nationality_iso,
	getIsApplyingEntries: () => {
		return state.entries
			.slice()
			.reverse()
			.filter((entry) => entry.applicationStatus?.isApplying);
	},
	getStudyTimings: (studyId: number) => {
		const timings = state.timings.filter((t) => t.studyId === studyId);

		const sortedStudyTimings = timings.sort((a, b) => {
			return a.startDate.getTime() - b.startDate.getTime();
		});

		return sortedStudyTimings;
	},
};

export default getters;

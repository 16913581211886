import { SessionAdapter } from '@/domain/session-adapter';
import globals from '@/utils/globals';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { ISessionService } from '@studyportals/student-interfaces';

export class LoginStatusCheckerPlugin {
	public eventAggregationService: IEventAggregationService;
	public sessionService: ISessionService;
	public sessionAdapter: SessionAdapter;

	constructor() {
		this.eventAggregationService = globals.eventAggregationService;
		this.sessionService = globals.sessionService;
		this.sessionAdapter = new SessionAdapter(this.sessionService, this.eventAggregationService);
	}

	public async initialize(): Promise<void> {
		await this.sessionAdapter.initialize();
	}
}

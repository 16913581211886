import store from '@/store';
import { ISubscriber, IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { ISessionService, SessionCreatedEvent } from '@studyportals/student-interfaces';

export class SessionAdapter implements ISubscriber<SessionCreatedEvent> {
	private sessionService: ISessionService;
	private eventAggregationService: IEventAggregationService;

	constructor(sessionService: ISessionService, eventAggregationService: IEventAggregationService) {
		this.eventAggregationService = eventAggregationService;
		this.sessionService = sessionService;
	}

	public async initialize(): Promise<void> {
		await this.handleSessionService(this.sessionService);
		this.eventAggregationService?.subscribeTo(SessionCreatedEvent.EventType, this);
	}

	public async notify(): Promise<void> {
		await store.actions.setLoggedInFlag(true);
	}

	private async handleSessionService(sessionService: ISessionService): Promise<void> {
		const session: object | null = await sessionService.getSession();

		if (session === null) {
			store.mutations.setLoggedInFlag(false);
			return;
		}

		await store.actions.setLoggedInFlag(true);
	}
}

import { ISubscriber } from '@studyportals/event-aggregation-service-interface';

import store from '@/store';
import {
	EventType,
	AnonymousStudentProfileUpdated,
	AnonymousStudentServiceReady,
	AnonymousStudentProfileSynced,
} from '@studyportals/anonymous-student-interfaces';
import { StudentField } from '@studyportals/studentdomain';
import globals from '@/utils/globals';
import { StudentRepository } from '@/domain/student-repository';

export class ProfileDataCheckerPlugin
	implements ISubscriber<AnonymousStudentServiceReady | AnonymousStudentProfileUpdated | AnonymousStudentProfileSynced>
{
	private studentRepository: StudentRepository;

	constructor() {
		this.studentRepository = this.newStudentRepository;
		globals.studentRepository = this.studentRepository;
	}

	get newStudentRepository(): StudentRepository {
		return new StudentRepository();
	}

	get eventAggregationService(): any {
		globals.eventAggregationService = window['EventAggregationService'];
		return window['EventAggregationService'];
	}

	public async notify(
		event: AnonymousStudentServiceReady | AnonymousStudentProfileUpdated | AnonymousStudentProfileSynced
	): Promise<void> {
		if (
			event.eventType === AnonymousStudentServiceReady.EventType ||
			event.eventType === AnonymousStudentProfileSynced.EventType
		) {
			await this.loadInitialData();
		}

		if (event.eventType === AnonymousStudentProfileUpdated.EventType) {
			this.handleStudentProfileUpdated(event as AnonymousStudentProfileUpdated);
		}
	}

	public initiate(): void {
		globals.eventAggregationService.subscribeTo(EventType.AnonymousStudentServiceReady, this, true);
		globals.eventAggregationService.subscribeTo(EventType.AnonymousStudentProfileUpdated, this);
		globals.eventAggregationService.subscribeTo(EventType.AnonymousStudentProfileSynced, this);
	}

	public async loadInitialData(): Promise<void> {
		const dataFields = [StudentField.NATIONALITY_COUNTRY_ISO];

		const studentData = await this.studentRepository.getFields(dataFields);

		if (studentData === null) {
			return;
		}

		store.mutations.setStudentNationalityISO(studentData[StudentField.NATIONALITY_COUNTRY_ISO] ?? '');
	}

	private handleStudentProfileUpdated(event: AnonymousStudentProfileUpdated): void {
		if (Object.keys(event.changes).indexOf(StudentField.NATIONALITY_COUNTRY_ISO) > -1) {
			store.mutations.setStudentNationalityISO(event.changes[StudentField.NATIONALITY_COUNTRY_ISO] ?? '');
		}
	}
}

import { WishlistEntry } from '../interfaces/wishlist-entry.interface';
import { IMutations } from '../interfaces/store.interfaces';

import state from './state';
import { FavouriteApplication, FavouriteApplicationStatus } from '@studyportals/wishlist-service-core';
import ITimingJourney from '@/interfaces/timings.interface';

const mutations: IMutations = {
	add: (entry: WishlistEntry) => {
		state.entries = [...state.entries, entry];
	},

	remove: (studyId: number) => {
		state.entries = state.entries.filter((entry) => {
			return entry?.study.id !== studyId;
		});
	},

	reversableRemove: (studyId: number) => {
		state.entries = state.entries.map((entry) => {
			if (entry && entry.study.id === studyId) {
				entry.deleted = true;
			}

			return entry;
		});
	},

	updateWishlist: (entries: WishlistEntry[]) => {
		state.entries = entries;
	},

	undoDeletion: (studyId: number) => {
		state.entries = state.entries.map((entry) => {
			if (entry && entry.study.id === studyId) {
				entry.deleted = false;
			}

			return entry;
		});
	},

	wishlistInitialized: () => {
		state.initialized = true;
	},

	toggleCompareMode: () => {
		state.compareMode = !state.compareMode;
	},

	enableCompareMode: () => {
		state.compareMode = true;
	},

	setLoggedInFlag: (isLoggedIn: boolean) => {
		state.isLoggedIn = isLoggedIn;
	},

	addComparisonStudy: (studyId: number) => {
		state.comparisonStudies = [...state.comparisonStudies, studyId];
	},

	removeComparisonStudy: (studyId: number) => {
		state.comparisonStudies = state.comparisonStudies.filter((study) => {
			return study !== studyId;
		});
	},
	removeAllComparsionStudies: () => {
		state.comparisonStudies = [];
	},
	setAreDependenciesLoaded: (areDependenciesLoaded: boolean) => {
		state.areDependenciesLoaded = areDependenciesLoaded;
	},

	setIsReady: (isReady: boolean) => {
		state.isReady = isReady;
	},

	setIsLoadingRoute: (isLoadingRoute: boolean) => {
		state.isLoadingRoute = isLoadingRoute;
	},

	setStudyApplicationStatus: (studyId: number, applicationStatus: FavouriteApplicationStatus) => {
		const entry = state.entries.find((e) => e.study.id === studyId);

		if (!entry) {
			return;
		}

		entry.applicationStatus = { ...applicationStatus };
	},

	setStudentNationalityISO: (iso: string) => (state.student_nationality_iso = iso),
	setTimings: (timings: ITimingJourney[]) => {
		state.timings = timings;
	},
	setStudyApplication: (studyId: number, application: FavouriteApplication | null) => {
		const entry = state.entries.find((e) => e.study.id === studyId);

		if (!entry) return;

		entry.application = application;
	},
};

export default mutations;

import { ListType } from '../enums/list-type';

export class RenderCardsCommand extends CustomEvent<RenderDecisionMakingServiceRequest> {
	constructor(identifier: string, listType: ListType, placeholderElement: Element) {
		const detail: RenderDecisionMakingServiceRequest = {
			identifier,
			listType,
			placeholderElement,
		};

		super('', { detail });
	}
}

interface RenderDecisionMakingServiceRequest {
	identifier: string;
	listType: ListType;
	placeholderElement: Element;
}

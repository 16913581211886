import { FavouriteAddedService } from '@/domain/favourite-added-service';
import { FavouriteApplicationChangedService } from '@/domain/favourite-application-changed-service';
import { FavouriteApplicationStatusChangedService } from '@/domain/favourite-application-status-changed-service';
import { FavouriteRemovedService } from '@/domain/favourite-removed-service';
import { WishlistEntry } from '@/interfaces/wishlist-entry.interface';
import store from '@/store';
import globals from '@/utils/globals';

import { Favourite, Wishlist } from '@studyportals/wishlist-service-core';

export class WishlistInitializationPlugin {
	private favouriteAddedService: FavouriteAddedService;
	private favouriteRemovedService: FavouriteRemovedService;
	private favouriteApplicationStatusChangedService: FavouriteApplicationStatusChangedService;
	private favouriteApplicationChanged: FavouriteApplicationChangedService;

	constructor() {
		this.favouriteAddedService = new FavouriteAddedService();
		this.favouriteRemovedService = new FavouriteRemovedService();
		this.favouriteApplicationStatusChangedService = new FavouriteApplicationStatusChangedService();
		this.favouriteApplicationChanged = new FavouriteApplicationChangedService();
	}

	public async initialize(): Promise<void> {
		const wishlistService = globals.wishlistService;
		const wishlist = await wishlistService.getWishlist();
		const entries = this.mapWishlistToEntries(wishlist);

		store.mutations.updateWishlist(entries);
		store.mutations.wishlistInitialized();

		this.favouriteAddedService.initialize();
		this.favouriteRemovedService.initialize();
		this.favouriteApplicationStatusChangedService.initialize();
		this.favouriteApplicationChanged.initialize();
	}

	private mapWishlistToEntries(wishlist: Wishlist): WishlistEntry[] {
		return wishlist.favourites.map((favourite: Favourite) => ({
			study: favourite.study,
			dateAdded: favourite.dateAdded,
			applicationStatus: favourite.properties?.applicationStatus,
			assessments: favourite.properties?.assessments,
			deleted: false,
			application: favourite.properties?.application,
		}));
	}
}

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import ITrackingObject from '../interfaces/tracking-object.interface';
import globals from './globals';

export default class Tracking {
	private snowPlow(trackingParams: ITrackingObject): void | null {
		if (typeof window['snowplow'] === 'undefined') {
			return null;
		}

		window['snowplow']('trackStructEvent', {
			category: trackingParams.category,
			action: trackingParams.action,
			label: trackingParams.label || '',
			property: trackingParams.property || '',
			value: trackingParams.value || 0,
		});
	}

	public trackCloseWishlist(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.ClosedDrawer,
		};

		this.snowPlow(trackObject);
	}

	public trackOpenWishlist(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.OpenedDrawer,
		};

		this.snowPlow(trackObject);
	}

	public trackTimeSpent(timeSpentTimerIdentifier: string, timeSpentConfigInterval: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.TimeSpent,
			label: timeSpentTimerIdentifier,
			value: timeSpentConfigInterval,
		};

		this.snowPlow(trackObject);
	}

	public trackLimitReached(limitNotificationKey: string): void {
		document.addEventListener('Notification', (event: any) => {
			if (event.detail.key !== limitNotificationKey) {
				return;
			}

			const trackObject = {
				category: globals.trackingLibrary.categories.WishlistOverview,
				action: globals.trackingLibrary.actions.WishlistLimitReachedShowed,
			};

			this.snowPlow(trackObject);
		});
	}

	public trackUndo(studyId: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.UndoRemove,
			value: studyId,
		};

		this.snowPlow(trackObject);
	}

	public trackRemoveFromWishlist(studyId: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.FavouriteRemove,
			value: studyId,
		};

		this.snowPlow(trackObject);
	}

	public trackClickOnStudyTitle(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.ProgramTitleClick,
		};

		this.snowPlow(trackObject);
	}

	public trackClickOnFooter(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.StudyCardFooterClick,
		};

		this.snowPlow(trackObject);
	}

	public trackPremiumClick(id: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.PremiumLinkClick,
			value: id,
		};

		this.snowPlow(trackObject);
	}

	public trackAddToComparison(studyId: number, label: string): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.ToggleComparisonStudy,
			label,
			value: studyId,
		};

		this.snowPlow(trackObject);
	}

	public trackInfoIconClick(studyId: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.UnavailableStudyIconClick,
			value: studyId,
		};

		this.snowPlow(trackObject);
	}
	public trackUniversityLinkClick(studyId: number): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.UnavailableStudyUniversityLinkClick,
			value: studyId,
		};

		this.snowPlow(trackObject);
	}

	public trackComparisonMode(label: string): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.ToggleCompareMode,
			label,
		};

		this.snowPlow(trackObject);
	}

	public trackStartComparison(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.StartComparison,
		};

		this.snowPlow(trackObject);
	}

	public trackImpressionWishlistWarning(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.UnavailableStudiesWarningShowed,
		};

		this.snowPlow(trackObject);
	}

	public trackInfoIconClickSelected(): void {
		const trackObject = {
			category: globals.trackingLibrary.categories.WishlistOverview,
			action: globals.trackingLibrary.actions.SelectedStudiesInfoClick,
		};

		this.snowPlow(trackObject);
	}
}

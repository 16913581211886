import { ISubscriber, IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { AnonymousStudentServiceReady, IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { StudentField, IStudent } from '@studyportals/studentdomain';

export class StudentClient implements ISubscriber<AnonymousStudentServiceReady> {
	private client?: IAnonymousStudentService;

	constructor() {
		this.eventAggregationService?.subscribeTo(AnonymousStudentServiceReady.EventType, this, true);
	}

	private get eventAggregationService(): IEventAggregationService {
		return window['EventAggregationService'];
	}

	public notify(event: AnonymousStudentServiceReady): void {
		this.client = event.anonymousStudentService;
	}

	public async getData(fields: StudentField[]): Promise<IStudent | null> {
		if (!this.client) return null;

		try {
			return await this.client.getStudentData(fields);
		} catch (e) {
			console.warn('Failed to retrieve student data', e);
			return null;
		}
	}
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  id: "decisionMakingService"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notify = _resolveComponent("Notify")!
  const _component_ComparisonMode = _resolveComponent("ComparisonMode")!
  const _component_Wishlist = _resolveComponent("Wishlist")!
  const _component_PreLoader = _resolveComponent("PreLoader")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.listType === _ctx.listTypes.WISHLIST)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Notify, {
          type: _ctx.limitNotificationType,
          eventKey: _ctx.limitNotificationKey
        }, {
          default: _withCtx(() => [
            _createTextVNode(" You reached the maximum amount of " + _toDisplayString(_ctx.favouriteLimit) + " programmes in your wishlist. ", 1 /* TEXT */),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDrawer && _ctx.openDrawer(...args)))
            }, "Time to narrow it down!")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["type", "eventKey"]),
        (_ctx.hasLoaded && _ctx.isReady)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _withDirectives(_createVNode(_component_ComparisonMode, null, null, 512 /* NEED_PATCH */), [
                [_vShow, _ctx.wishlistOpen]
              ]),
              _withDirectives(_createVNode(_component_Wishlist, null, null, 512 /* NEED_PATCH */), [
                [_vShow, _ctx.wishlistOpen]
              ]),
              _withDirectives(_createVNode(_component_PreLoader, null, null, 512 /* NEED_PATCH */), [
                [_vShow, _ctx.wishlistOpen && !_ctx.initialized]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _withDirectives(_createVNode(_component_Loading, null, null, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isLoadingRoute]
        ]),
        _createVNode(_component_router_view)
      ]))
}
import { Product, ITrackingData, Tracker, Action } from '@studyportals/datalake-event-tracker';

export default class JourneyTracker {
	public tracker: Tracker;

	constructor() {
		this.tracker = new Tracker(Product.MY_JOURNEY);
	}

	public trackViewChecklist(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.IMPRESSION,
			label: JourneyLabels.VIEW_CHECKLIST,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.VISITED_PAGE);
	}

	public trackEvaluateStudy(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.IMPRESSION,
			label: JourneyLabels.EVALUATE_STUDY,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.VISITED_PAGE);
	}

	public trackRenderedCreateChecklist(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.IMPRESSION,
			label: JourneyLabels.CREATED_CHECKLIST,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.VISITED_PAGE);
	}

	public trackCreatedChecklist(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.CLICK,
			label: JourneyLabels.CREATED_CHECKLIST,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.CREATED_CHECKLIST);
	}

	public trackEditedChecklist(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.CLICK,
			label: JourneyLabels.EDITED_CHECKLIST,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.EDITED_CHECKLIST);
	}

	public trackGoingToApply(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.CLICK,
			label: JourneyLabels.GOING_TO_APPLY,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.GOING_TO_APPLY);
	}

	public trackHasApplied(studyId: number): void {
		const trackingData: ITrackingData = {
			category: Product.MY_JOURNEY,
			action: Action.CLICK,
			label: JourneyLabels.HAS_APPLIED,
			value: studyId,
		};

		this.tracker.trackStructuredEvent(trackingData, JourneyLabels.HAS_APPLIED);
	}
}

enum JourneyLabels {
	HAS_APPLIED = 'my_journey_has_applied',
	GOING_TO_APPLY = 'my_journey_going_to_apply',
	VISITED_PAGE = 'my_journey_visited_page',
	EDITED_CHECKLIST = 'my_journey_edited_checklist',
	CREATED_CHECKLIST = 'my_journey_created_checklist',
	EVALUATE_STUDY = 'my_journey_evaluate_study',
	VIEW_CHECKLIST = 'my_journey_view_checklist',
}

import { JourneyRouteName } from '@/domain/enums/journey-route-name';
import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { ListType } from 'interfaces';

export default {
	path: 'evaluate/',
	component: (): any => import('@/presentation/components/Views/Journey/JourneyEvaluate/JourneyEvaluate.vue'),
	meta: {
		listType: ListType.JOURNEY_EVALUATE,
	},
	children: [
		{
			name: JourneyRouteName.EVALUATE_OVERVIEW,
			path: '',
			component: (): any =>
				import(
					'@/presentation/components/Views/Journey/JourneyEvaluate/JourneyEvaluateOverview/JourneyEvaluateOverview.vue'
				),
		},
		{
			name: JourneyRouteName.EVALUATE_DETAILS,
			path: ':id/view/',
			component: (): any =>
				import(
					'@/presentation/components/Views/Journey/JourneyEvaluate/EvaluateProgrammeDetails/EvaluateProgrammeDetails.vue'
				),
			props: (props): Record<string, any> => ({ id: Number(props.params.id) }),
			beforeEnter: (to, from, next): void => {
				const entry = store.getters.getEntry(Number(to.params.id));
				return entry ? next() : next({ name: JourneyRouteName.EVALUATE_OVERVIEW });
			},
		},
	],
} as RouteRecordRaw;
